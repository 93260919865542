@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

[data-light-mode='false'] {
  .siteFooter {
    background: var(--light-mode-bg);
  }
}

.siteFooter {
  font-size: 16px;
  line-height: 24px;
  padding: 48px 0;
  background: $secondary;
  // border-radius: 24px 24px 0 0;
  color: #fff;

  :last-child {
    margin-bottom: 5px;
  }

  .logoBlock {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }

  h4 {
    margin: 0;
    padding: 0px 8px;
    min-width: 118px;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.5px;
    color: #fff;
    background: $secondary;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
  }

  .active {
    color: $theme-medium;
  }

  a {
    color: #fff;

    &:hover,
    &:focus {
      color: $theme-medium;
    }
  }
}
.sgLogo {
  height: 40px;
  margin-left: 13px;
    // padding-left: 20px;
    border-left: 1px solid rgba(222, 222, 222, 0.5019607843);
}
.footerMenu {

  li {
    margin-bottom: 12px;
    width: 50%;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.footerLogo {
  // margin-bottom: 10px;
  width: 235px;
}

.quickLink {
  min-height: 24px;

  li {
    margin-right: 20px;
    letter-spacing: 0.2px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.downloadApp {
  a {
    margin-right: 20px;
    display: block;

    &:last-child {
      margin-right: 0;
    }
  }
}

.socialMenu {
  li {
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }

  a {
    display: block;
    width: 32px;
  }
}

/***** Responsive CSS Start ******/

@media (min-width: 768px) and (max-width: 991px) {
  .siteFooter {
    h4 {
      padding: 0px 6px;
      min-width: 90px;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .footerMenu {
    li {
      margin-bottom: 10px;
    }
  }

  .footerLogo {
    width: 150px;
  }

  .quickLink {
    li {
      margin-right: 8px;
      font-size: 11.5px;
    }
  }

  .downloadApp {
    a {
      margin-right: 10px;
    }
  }
}

@media (max-width: 767px) {
  .siteFooter {
    padding: 0px 0 20px;

    a {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .footerMenu {
    li {
      margin-bottom: 10px;
    }
  }

  .footerBottom {
    padding-top: 24px;
  }

  .shareBlock {
    padding: 24px 0 20px;
  }

  .socialMenu {
    li {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.mtNe5{
  margin-top: -5px;
}